import type { OngoingSale, UpcomingSale } from '~/types/api/promotion'

export default function usePromo() {
  const voucher = useOrder().voucher

  const isGojek = computed(() => voucher.value?.code === 'JAGOJEK')

  const isGrab = computed(() => voucher.value?.code === 'GRABBENEFITS')

  const isKredivo = computed(
    () => voucher.value?.code === 'JAGOFONKREDIVOAUG24',
  )

  const ongoingSale = useAsyncData(
    'ongoing-sale',
    () => useNuxtApp().$api.product.getOngoingSales(),
    {
      getCachedData: (key, nuxt): OngoingSale =>
        nuxt.payload.data[key] || nuxt.static.data[key],
      dedupe: 'defer',
    },
  )

  const upcomingSale = useAsyncData(
    'upcoming-sale',
    () => useNuxtApp().$api.product.getUpcomingSales(),
    {
      getCachedData: (key, nuxt): UpcomingSale =>
        nuxt.payload.data[key] || nuxt.static.data[key],
      dedupe: 'defer',
    },
  )

  return {
    isGojek,
    isGrab,
    isKredivo,
    ongoingSale,
    upcomingSale,
  }
}
