import type { Address, Order, OrderItem } from '~/types/api'

export default function useOrder() {
  const { getWarrantyPrice } = useProduct()

  // TODO: Implement custom serializer to allow `Map` in LocalStorage
  const order = useLocalStorage<Order>('order', {
    items: {},
    delivery: null,
    fee: {
      name: null,
      amount: null,
    },
    shipping: {
      id: null,
      method: null,
      slug: null,
      price: null,
    },
  })

  const items = computed(() => {
    return Object.values(order.value.items).map((item) => ({
      ...item,
      warrantyPrice: getWarrantyPrice(
        item.product.price,
        item.warranty.percent,
      ),
    }))
  })

  const delivery = computed(() => order.value.delivery)

  const shipping = computed(() => order.value.shipping)

  const voucher = computed(() => order.value.voucher)

  const fee = computed({
    get: () => {
      return order.value.fee
    },
    set: (
      fee: { name: string; amount: number } | { name: null; amount: null },
    ) => {
      order.value.fee = fee
    },
  })

  const subTotal = computed(() => {
    return (
      items.value?.reduce((acc, item) => {
        return acc + (item.product.price + item.warrantyPrice) * item.quantity
      }, 0) || 0
    )
  })
  const total = computed(() => subTotal.value - (voucher.value?.discount || 0))
  const grandTotal = computed(() => total.value + (fee.value?.amount || 0))

  const addItem = (item: OrderItem) => {
    // Check if any reseller is different
    items.value.forEach((i) => {
      if (i.product.reseller !== item.product.reseller) {
        throw new Error('Cannot mix items from different resellers')
      }
    })

    order.value.items[item.product.id] = item
  }

  const removeItem = (productId: number) => {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete order.value.items[productId]
  }

  const clearItems = () => {
    order.value.items = {}
  }

  const setVoucher = (voucher?: { code: string; discount: number }) => {
    order.value.voucher = voucher
  }

  const setShipping = (shipping: {
    id: number
    method: string
    slug: string
    price: number
  }) => {
    order.value.shipping = {
      id: shipping.id,
      method: shipping.method,
      slug: shipping.slug,
      price: shipping.price,
    }
  }

  const setDelivery = (address: Address) => {
    order.value.delivery = address
  }

  const reset = () => {
    const { isGojek, isGrab } = usePromo()
    order.value = {
      items: {},
      delivery: null,
      fee: {
        name: null,
        amount: null,
      },
      shipping: {
        id: null,
        method: null,
        slug: null,
        price: null,
      },

      // NOTE: Don't remove the voucher if the user is from Gojek or Grab
      ...(isGojek.value || isGrab.value
        ? { voucher: order.value.voucher }
        : {}),
    }
  }

  return {
    order,
    items,
    delivery,
    shipping,
    fee,
    voucher,
    addItem,
    removeItem,
    clearItems,
    setVoucher,
    setShipping,
    setDelivery,
    reset,

    subTotal,
    total,
    grandTotal,
  }
}
